<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Course Title -->
    <PageHeader class="pb-4" heading="Compare Courses">
      <v-row class="pa-0 ma-0">
        <v-btn v-if="(courses.data.c1 || courses.data.c2 || courses.data.c3) && !store.app.onMobile" depressed
               color="transparent" data-cy="reset_comparison" @click="$router.push({ query: null })">
          <v-icon class="mr-2" color="accent">mdi-cached</v-icon>
          <span v-if="!store.app.onMobile">Reset</span>
        </v-btn>
        <v-spacer/>
        <ShareButton v-if="(courses.data.c1 || courses.data.c2 || courses.data.c3) && !store.app.onMobile"
                     full-link message="Link copied to clipboard!"/>
      </v-row>
    </PageHeader>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row class="mt-0" justify="center">
      <v-col class="contentMaxWidth pt-2">
        <vue-position-sticky :offsetTop="$vuetify.breakpoint.smAndDown ? 68 : 75">
          <v-row class="courseRow">
            <v-col cols="6" class="col-sm-4 pt-1 pl-0 pr-2 pr-sm-4">
              <v-autocomplete v-model="courses.selected.c1" solo-inverted background-color="border" hide-no-data flat
                              :items="store.data.courseList" height="50" label="Select a course" hide-details
                              class="autoComplete" cache-items ref="c1" data-cy="c1" @change="autoBlur('c1')"
                              :open-on-clear="store.app.onMobile" clearable :loading="courses.selected.c1 && !courses.data.c1"/>
            </v-col>
            <v-col cols="6" class="col-sm-4 pt-1 px-2 pr-0 pr-sm-2">
              <v-autocomplete v-model="courses.selected.c2" solo-inverted background-color="border" hide-no-data flat
                              :items="store.data.courseList" height="50" label="Select a course" hide-details
                              class="autoComplete" cache-items ref="c2" data-cy="c2" @change="autoBlur('c2')"
                              :open-on-clear="store.app.onMobile" clearable :loading="courses.selected.c2 && !courses.data.c2"/>
            </v-col>
            <v-col cols="6" class="col-sm-4 pt-1 pl-2 pl-sm-4 pr-0 d-none d-sm-block">
              <v-autocomplete v-model="courses.selected.c3" solo-inverted background-color="border" hide-no-data flat
                              :items="store.data.courseList" height="50" label="Select a course" hide-details
                              class="autoComplete" cache-items ref="c3" data-cy="c3" @change="autoBlur('c3')"
                              :open-on-clear="store.app.onMobile" clearable :loading="courses.selected.c3 && !courses.data.c3"/>
            </v-col>
          </v-row>
        </vue-position-sticky>
        <v-row v-if="!(courses.data.c1 || courses.data.c2 || courses.data.c3)" class="py-12 my-12 mx-0">
          <v-col>
            <v-row justify="center"><v-icon color="accent" size="75" class="py-6">mdi-scale-unbalanced</v-icon></v-row>
            <p class="text-h5 text-md-h4 text-lg-h4 text-center">Select a course to get started!</p>
            <p class="text--secondary text-body-1 text-center">
              Quickly compare up to three courses across a variety of criteria
            </p>
          </v-col>
        </v-row>
        <div v-else style="margin: -12px">
          <div class="my-8 text-center">
            <v-row v-for="index in 2" :key="index" class="mt-0">
              <v-col v-for="(course, idx) in courseData" :key="idx" cols="6" :class="idx < 2 ? 'col-sm-4' : 'col-sm-4 d-none d-sm-block'">
                <div v-if="index === 1 && course">
                  <chip campus :text="course.campus" class-name="mb-2"/>
                  <p class="mb-0 font-weight-medium text-h5 text-sm-h4">{{ course.code }}</p>
                  <h4 class="font-weight-medium mb-4">{{ course.course.slice(10) }}</h4>
                </div>
                <div v-else-if="course">
                  <v-row justify="center" class="mb-2 mx-0">
                    <p class="font-weight-medium text-body-1 text-sm-h6 mb-0">
                      <v-icon class="mr-1 mr-md-2" color="primary">mdi-bird</v-icon>{{ course.bird }}
                    </p>
                    <p class="font-weight-medium text-body-1 text-sm-h6 ml-3 mb-0">
                      <v-icon class="mr-1 mr-md-2" color="error">mdi-finance</v-icon>{{ course.drop }}%
                    </p>
                    <p class="font-weight-medium text-body-1 text-sm-h6 ml-3 mb-0">
                      <v-icon class="mr-1 mr-md-2" color="warning">mdi-star</v-icon>
                      {{ formatRating(course.numReviews > 0 ? course.rating : -1, true, false) }}
                    </p>
                  </v-row>
                  <v-divider class="mx-8 my-4"/>
                  <p class="accent--text font-weight-medium hover"
                     @click="$router.push({ path: '/courses', query: { c: course.code } })">View course
                    <v-icon color="accent" small class="mb-0">mdi-chevron-right</v-icon>
                  </p>
                </div>
                <div v-else/>
              </v-col>
            </v-row>
          </div>
          <div v-for="(section, idx) in fields" :key="idx" class="mb-12 mx-0">
            <h1 class="mb-4">{{ section.header }}</h1>
            <v-divider class="mb-12"/>
            <span v-if="section.header === 'Course Info'">
              <v-row v-for="(field, idx) in section.sections" :key="idx">
                <v-col v-for="(course, idx) in courseData" :key="idx" cols="6" :class="idx < 2 ? 'col-sm-4' : 'col-sm-4 d-none d-sm-block'">
                  <div v-if="course">
                    <p class="font-weight-bold mb-1">{{ field.label }}</p>
                    <p v-if="field.label !== 'Semesters'" class="mb-6">
                      {{ Array.isArray(course[field.field]) ? course[field.field].join(', ') : course[field.field] || '---' }}
                    </p>
                    <semester-chips v-else class="mt-2 mb-4" :icon="!store.app.onDesktop" no-semester-text="---"
                                    :course="course.course.slice(0, 8)" :semesters="course[field.field]" text-class="mb-0"/>
                  </div>
                  <div v-else/>
                </v-col>
              </v-row>
            </span>
            <span v-else-if="section.header === 'Course History'">
              <apexchart type="bar" :options="chartOptions" :series="courses.history.series" height="500px" style="width: 100%"/>
            </span>
            <span v-else>
              <v-row>
                <v-col v-for="(course, idx) in courseData" :key="idx" cols="6" :class="idx < 2 ? 'col-sm-4' : 'col-sm-4 d-none d-sm-block'">
                  <div v-if="course">
                    <v-row class="justify-center mb-0 mt-5 mt-sm-0">
                      <v-icon color="warning" x-large class="mr-3">mdi-star</v-icon>
                      <p class="mb-0 mr-2 text-h2 font-weight-bold">{{ formatRating(course.numReviews > 0 ? course.rating : -1, true, false) }}</p>
                      <p class="text-body-1 text--secondary align-self-end mb-1">/ 5</p>
                    </v-row>
                    <p class="text--secondary text-center mb-0 mb-sm-10">{{ course.numReviews }} reviews</p>
                    <v-divider class="mx-12"/>
                  </div>
                  <div v-else/>
                </v-col>
              </v-row>
              <v-row v-for="(field, idx) in section.sections" :key="idx">
                <v-col v-for="(course, idx) in courseData" :key="idx" cols="6"
                       :class="idx < 2 ? 'col-sm-4' : 'col-sm-4 d-none d-sm-block'" align-self="center">
                  <div v-if="course && course.numReviews" class="text-center">
                    <v-icon size="65" color="accent" class="mt-8 mb-4">{{ icons[field.field] }}</v-icon>
                    <h2>{{ formatRating(course[field.field], true, false) }}</h2>
                    <p class="text--secondary">{{ field.label }}</p>
                  </div>
                  <h1 v-else-if="course" class="text-center">-</h1>
                  <div v-else/>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { sortSemesters } from '@/utils/shared/helpers'
import ShareButton from '@/components/ShareButton'
import SemesterChips from '@/components/SemesterChips'
import PageHeader from '@/components/PageHeader'
import Chip from '@/components/Chip.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'Compare',
  components: { Chip, PageHeader, ShareButton, SemesterChips },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    courses: {
      selected: { c1: null, c2: null, c3: null },
      data: { c1: null, c2: null, c3: null },
      history: { series: [], labels: [] }
    },
    colours: ['#1976D2', '#FFA333', '#04DB8E'],
    icons: {
      recommend: 'mdi-thumb-up-outline',
      workload: 'mdi-gauge',
      understanding: 'mdi-head-snowflake-outline',
      evaluations: 'mdi-note-edit-outline',
      environment: 'mdi-google-classroom',
      application: 'mdi-hail',
      stimulating: 'mdi-lightbulb-on-10'
    },
    fields: [
      {
        header: 'Course Info',
        sections: [
          { field: 'section', label: 'Field of Study' },
          { field: 'breadth', label: 'Breadth REQ' },
          { field: 'distribution', label: 'Distribution REQ' },
          { field: 'prereq', label: 'Prerequisites' },
          { field: 'preparation', label: 'Recommended Preparation' },
          { field: 'exclusion', label: 'Course Exclusions' },
          { field: 'notes', label: 'Notes' },
          { field: 'semesters', label: 'Semesters' }
        ]
      },
      {
        header: 'Course History',
        sections: []
      },
      {
        header: 'Reviews',
        sections: [
          { field: 'recommend', label: 'Would recommend' },
          { field: 'workload', label: 'Course workload' },
          { field: 'understanding', label: 'Improved understanding' },
          { field: 'evaluations', label: 'Evaluations improved understanding' },
          { field: 'environment', label: 'Learning environment' },
          { field: 'application', label: 'Opportunities to demonstrate knowledge' },
          { field: 'stimulating', label: 'Intellectually stimulating' }
        ]
      }
    ]
  }),
  mounted () {
    Object.entries(this.$route.query).forEach(course => {
      if (['c1', 'c2', 'c3'].includes(course[0])) {
        if (course[0] === 'c3' && this.store.app.onMobile) {
          this.$toast.warning('Please note your devices\' screen size may prevent you from viewing all 3 courses!')
        }
        this.getDetails(course[0], course[1])
      }
    })
  },
  computed: {
    courseData () { return Object.entries(this.courses.data).map(data => data[1]) },
    dropRateAvg: function () {
      const averages = []
      if (!this.store.app.onMobile) {
        for (let i = 0; i < this.courses.history.series.length; i++) {
          const course = this.courses.history.series[i]
          const average = course.data.filter(x => !!x.y).reduce((a, b) => a + (parseFloat(b.y)), 0) / course.data.filter(x => !!x.y).length
          if (average) {
            averages.push(
              {
                y: average,
                borderColor: this.colours[i],
                borderWidth: 2,
                strokeDashArray: 0,
                label: {
                  borderColor: this.colours[i],
                  offsetY: 5,
                  style: { color: '#FFFFFF', background: this.colours[i], fontWeight: 700 },
                  text: 'Avg: ' + average.toFixed(1)
                }
              }
            )
          }
        }
      }
      return averages
    },
    chartOptions: function () {
      return {
        chart: {
          stacked: false,
          toolbar: { show: false, tools: { zoom: false } },
          background: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'
        },
        colors: this.colours,
        dataLabels: { enabled: true },
        theme: { mode: this.store.app.darkMode ? 'dark' : 'light' },
        markers: { size: 0 },
        yaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          title: { text: 'Drop Rate %' }
        },
        xaxis: { type: 'category', tooltip: { enabled: false } },
        legend: { position: 'top', horizontalAlign: 'right', offsetX: -10 },
        annotations: { yaxis: this.dropRateAvg }
      }
    }
  },
  watch: {
    'courses.selected': {
      deep: true,
      handler () {
        Object.entries(this.courses.selected).forEach((entry) => {
          const query = { ...this.$route.query }
          if (entry[1] && this.$route.query[entry[0]] !== entry[1].slice(0, 8)) {
            query[entry[0]] = entry[1].slice(0, 8)
            this.$router.push({ query: query })
          } else if (!entry[1] && this.courses.data[entry[0]]) {
            delete query[entry[0]]
            this.$router.push({ query: query })
            this.courses.data[entry[0]] = null
            this.updateHistory()
          }
        })
      }
    },
    '$route.query': function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.courses.selected = { c1: null, c2: null, c3: null }
        this.courses.data = { c1: null, c2: null, c3: null }
        // GTAG
        this.$gtag.event('compare_reset', { value: 1 })
      } else {
        const query = Object.values(this.$route.query).join('_')
        if (query.indexOf('_') > -1) this.$gtag.event('compare_' + query, { value: 1 })
        Object.entries(this.$route.query).forEach(course => {
          if (['c1', 'c2', 'c3'].includes(course[0]) && course[1] !== this.courses.data[course[0]]?.code) {
            this.getDetails(course[0], course[1])
          }
        })
      }
    }
  },
  methods: {
    async getDetails (entry, code) {
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { ' +
          'code, campus, course, section, bird, drop, prereq, preparation, exclusion, breadth, distribution,' +
          'notes, semesters, rating, recommend, workload, understanding, evaluations, environment, application,' +
          'stimulating, numReviews } }',
        variables: { course: code },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data.courseInfo.length === 0) {
              const query = { ...this.$route.query }
              delete query[entry]
              this.$router.push({ query: query })
              this.$toast.warning('Sorry! We could not find a course matching: ' + code)
            } else {
              this.courses.data[entry] = graphQlRes.data.courseInfo[0]
              this.getDropHistory(entry, code)
              // Update autocomplete if needed
              if (this.courses.selected[entry] === null && this.courses.data[entry]) {
                this.courses.selected[entry] = this.courses.data[entry].course
              }
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getDropHistory (entry, code) {
      const q = {
        query: 'query getDropHistory($course: String!) { getDropHistory(course: $course) { _id, drop } }',
        variables: { course: code },
        operationName: 'getDropHistory'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.courses.data[entry].history = graphQlRes.data.getDropHistory
            this.updateHistory()
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    updateHistory () {
      const chartData = { series: [], labels: [] }
      const labelSet = new Set()
      // Get list of every section regardless of /course availability
      Object.entries(this.courses.data).forEach(course => {
        if (course[1] && course[1].history) [...course[1].history.map(sem => { return sem._id })].forEach(labelSet.add, labelSet)
      })
      // Sort the labels
      chartData.labels = Array.from(labelSet)
      chartData.labels.sort(sortSemesters)
      // Create the apex chart data and categories
      Object.entries(this.courses.data).forEach(course => {
        if (course[1] && course[1].history) {
          const seriesObj = { name: course[1].code, data: [] }
          chartData.labels.forEach(sem => {
            const dataIDX = course[1].history.findIndex(entry => { return entry._id === sem })
            seriesObj.data.push({ x: sem, y: dataIDX > -1 ? course[1].history[dataIDX].drop.toFixed(1) : null })
          })
          chartData.series.push(seriesObj)
        }
      })
      this.courses.history = chartData
    },
    formatRating (rating, style, int) {
      if (int && rating % 1 === 0) {
        return rating
      } else {
        return rating >= 0 ? rating.toFixed(1) : style ? 'N/A' : '--.--'
      }
    },
    autoBlur (course) {
      if (this.courses.selected[course] && this.store.app.onMobile) this.$refs[course].blur()
    }
  }
}
</script>

<style scoped>
  >>>.theme--light.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #eee;
  }
  >>>.theme--dark.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #000000;
  }
  >>>.vue-position-sticky {
    z-index: 2 !important;
  }
  .courseRow {
    background: var(--v-background-base);
  }

</style>
