<template>
  <span @click="getLink">
    <slot>
      <v-btn color="transparent" :icon="store.app.onMobile" depressed class="ml-3 px-2" text-capitalize>
        <v-icon color="accent" class="mr-2">mdi-link-variant-plus</v-icon>
        {{ store.app.onMobile ? '' : 'Share' }}
      </v-btn>
    </slot>
  </span>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'ShareButton',
  props: {
    fullLink: { type: Boolean, default: false },
    page: { type: String, default: '' },
    value: { type: String, default: '' },
    message: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  methods: {
    async getLink () {
      if (this.disabled) return
      let copied = false
      let link = ''
      const url = window.location

      if (this.fullLink) {
        link = url
      } else {
        link = url.href.slice(0, url.href.indexOf(url.pathname) + 1)
        if (this.value.length) {
          link += (this.page ? this.page[0] : this.$route.path[1]) + '/' + this.value
        }
      }

      const usingFirefox = navigator.userAgent.match(/Firefox/)
      const usingSafari = navigator.userAgent.match(/Safari/i)
      const onIOS = navigator.userAgent.match(/ipad|ipod|iphone/i)

      // Special logic for safari
      if (usingSafari || usingFirefox) {
        const clipBoardElem = document.createElement('input')
        document.body.appendChild(clipBoardElem)
        clipBoardElem.value = link
        clipBoardElem.select()
        // handle iOS devices
        if (onIOS) {
          clipBoardElem.contenteditable = true
          clipBoardElem.readonly = false

          const range = document.createRange()
          range.selectNodeContents(clipBoardElem)

          const selection = window.getSelection()
          selection.removeAllRanges()
          selection.addRange(range)
          clipBoardElem.setSelectionRange(0, 999999)
        }
        copied = document.execCommand('copy')
        document.body.removeChild(clipBoardElem)
      } else {
        copied = await navigator.clipboard.writeText(link)
      }
      // Check if success and let user know
      if (copied) {
        let tag = ''
        if (url.pathname[1] === 't') {
          tag = 'timetable'
        } else if (url.pathname.slice(1) === 'compare') {
          tag = 'comparison'
        } else {
          tag = url.pathname.slice(1) + '_' + this.value
        }
        this.$gtag.event('shared_' + tag, { value: 1 })
        this.$toast.info(this.message || 'Link for ' + this.value + ' copied to clipboard!')
      } else {
        this.$toast.error('Could not copy the link to your clipboard!')
      }
    }
  }
}
</script>

<style scoped>

</style>
